/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

// Move top mobile menu into Slimmenu
jQuery('#mobile-top-menu').prependTo('#menu-main-menu');

jQuery('#menu-main-menu').slimmenu(
{
    resizeWidth: '992',
    collapserTitle: '',
    animSpeed: 'medium',
    easingEffect: null,
    indentChildren: false,
    childrenIndenter: '&nbsp;'
});


// https://owlcarousel2.github.io/OwlCarousel2/docs/started-installation.html
jQuery(document).ready(function(){
  jQuery('.owl-carousel').owlCarousel({
	loop:true,
    responsiveClass:true,
    nav:true,
    navText : ["&lsaquo;","&rsaquo;"],
    autoplay:true,
    responsive:{
        0:{
            items:3,

        },
        600:{
            items:6,

        },
        992:{
            items:6,
        }
    }
  });
});

jQuery(document).ready(function(){
jQuery(".collapse-button").click(function() {
    jQuery(this).toggleClass("open");
});

jQuery(".sub-menu li.menu-item-has-children").click(function() {
    jQuery(this).toggleClass("open");
});

});

//https://stackoverflow.com/questions/13030923/insert-a-br-tag-after-x-words-using-jquery
jQuery(".three-3").each(function() {
    var html = jQuery(this).html().split(" ");
    html = html[0] + "<br />" + html.slice(1).join(" ");
    jQuery(this).html(html);
});

//https://www.w3schools.com/howto/howto_js_filter_lists.asp
function myFunction() {
    // Declare variables
    var input, filter, div, article, a, i;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();
    div = document.getElementById("myUL");
    article = div.getElementsByTagName('article');

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < article.length; i++) {
        a = article[i].getElementsByTagName("a")[0];
        if (a.innerHTML.toUpperCase().indexOf(filter) > -1) {
            article[i].style.display = "";
        } else {
            article[i].style.display = "none";
        }
    }
}
